/* eslint-disable no-param-reassign */
import { AnyAction, AsyncThunk, combineReducers, configureStore } from "@reduxjs/toolkit";

import appointments from "./appointments/appointmentsSlice";
import articles from "./articles/articlesSlice";
import articleSlots from "./articleSlots/articleSlotsSlice";
import auth, { logout } from "./auth/authSlice";
import calendarPresets from "./calendarPresets/calendarPresetsSlice";
import clients from "./clients/clientsSlice";
import closedDates from "./closedDates/closedDatesSlice";
import employeeBlockTimes from "./employeeBlockTimes/employeeBlockTimesSlice";
import employees from "./employees/employeesSlice";
import shifts from "./employeesShifts/employeesShiftsSlice";
import industries from "./industries/industriesSlice";
import invoicePayments from "./invoicePayments/invoicePaymentsSlice";
import invoiceSequencing from "./invoiceSequencing/invoiceSequencingSlice";
import myPermissions from "./myPermissions/myPermissionsSlice";
import onlinePayments from "./onlinePayments/onlinePaymentsSlice";
import organization from "./organization/organizationSlice";
import organizationOnlineProfile from "./organizationOnlineProfile/organizationOnlineProfileSlice";
import organizationOpeningHours from "./organizationOpeningHours/organizationOpeningHoursSlice";
import outlets from "./outlets/outletsSlice";
import owners from "./owners/ownersSlice";
import packageCategories from "./packageCategories/packageCategoriesSlice";
import packages from "./packages/packagesSlice";
import packageVariants from "./packagesVariants/packageVariantsSlice";
import paymentTypes from "./paymentType/paymentTypesSlice";
import permissionOptions from "./permissionOptions/permissionOptionsSlice";
import permissionSections from "./permissionSections/permissionSectionsSlice";
import roles from "./roles/rolesSlice";
import sales from "./sales/salesSlice";
import serviceCategories from "./serviceCategories/serviceCategoriesSlice";
import services from "./services/servicesSlice";
import serviceVariants from "./servicesVariants/serviceVariantsSlice";
import {
  activePromotions,
  addOns,
  appointmentDrawer,
  appointmentsOverview,
  areas,
  calendar,
  checkout,
  checkoutInvoices,
  cities,
  clientAnalytics,
  clientHairTypes,
  clientPreferredBeverages,
  clientScalpTypes,
  clientSkinTypes,
  clientsWallets,
  deposits,
  discountCodes,
  expenseTypes,
  expenses,
  flexBooking,
  industryCategories,
  loyaltyPrograms,
  notifications,
  onlineBookingStatus,
  outletFeaturedServices,
  outletStocks,
  packageCategoriesV2,
  packageVariantsV2,
  packagesV2,
  peepPayLinks,
  peepTransactions,
  productBrands,
  productCategories,
  productInvoices,
  products,
  promotions,
  purchasableSales,
  resources,
  serviceCategoriesV2,
  serviceVariantsV2,
  servicesV2,
  smsReminders,
  stockTransactions,
  subscriptionPurchases,
  subscriptions,
  supplierContacts,
  suppliers,
  tags,
  transactions,
  voucherPurchases,
  vouchers,
  zones,
} from "./slices";
import resourceBlockTimes from "./slices/resourceBlockTimes/resourceBlockTimesSlice";
import workingHours from "./workingHours/workingHoursSlice";

export { default as useDispatch } from "./utils/useDispatch";
export { default as useSelector } from "./utils/useSelector";

const rootReducer = combineReducers({
  appointments,
  appointmentsOverview,
  articleSlots,
  articles,
  auth,
  calendar,
  calendarPresets,
  clients,
  closedDates,
  employees,
  employeeBlockTimes,
  shifts,
  industries,
  invoicePayments,
  invoiceSequencing,
  onlinePayments,
  onlineBookingStatus,
  organization,
  organizationOnlineProfile,
  organizationOpeningHours,
  outlets,
  owners,
  packageCategories,
  packageVariants,
  packages,
  paymentTypes,
  permissionOptions,
  permissionSections,
  roles,
  serviceCategories,
  services,
  serviceVariants,
  sales,
  transactions,
  myPermissions,
  workingHours,
  productCategories,
  productBrands,
  products,
  suppliers,
  packageCategoriesV2,
  packagesV2,
  packageVariantsV2,
  servicesV2,
  serviceCategoriesV2,
  serviceVariantsV2,
  supplierContacts,
  stockTransactions,
  outletStocks,
  productInvoices,
  purchasableSales,
  checkout,
  checkoutInvoices,
  smsReminders,
  subscriptions,
  subscriptionPurchases,
  notifications,
  addOns,
  expenseTypes,
  outletFeaturedServices,
  expenses,
  vouchers,
  voucherPurchases,
  deposits,
  industryCategories,
  tags,
  promotions,
  activePromotions,
  clientsWallets,
  resources,
  resourceBlockTimes,
  clientAnalytics,
  loyaltyPrograms,
  peepPayLinks,
  clientSkinTypes,
  clientHairTypes,
  clientScalpTypes,
  clientPreferredBeverages,
  appointmentDrawer,
  flexBooking,
  peepTransactions,
  zones,
  areas,
  cities,
  discountCodes,
});

const reducer = (state: any, action: AnyAction) => {
  if (action.type === logout.type) state = {} as ReturnType<typeof rootReducer>;
  return rootReducer(state, action);
};

const store = configureStore({ reducer, devTools: process.env.NODE_ENV !== "production" });

export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;

// Since stores are written in JS and I am consuming them in TS they were
// complaining about missing properties, these Generic types fix that
export type GenericThunk = AsyncThunk<{ [args: string]: any }, any, {}>;
export type GenericDispatch = typeof store.dispatch;

export default store;
