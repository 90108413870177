import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getErrorMessage } from "helpers/errors";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import createThunkFromApi from "utils/createThunkFromApi";

import api from "./ownersApi";

const ownerAdapter = createEntityAdapter();

const initialState = ownerAdapter.getInitialState({
  isLoading: false,
  isRequestPending: false,
  error: "",
});

const { selectById, selectAll } = ownerAdapter.getSelectors((state) => state.owners);

export const selectAllOwners = selectAll;

export const selectOwnerById = (id) => (state) => selectById(state, id);

export const selectAreOwnersLoading = (state) => state.isLoading;

export const getAllOwners = createThunkFromApi("owners/getAllOwners", api.getAllOwners);

export const getOwner = createThunkFromApi("owners/getOwner", api.getOwner);

export const createOwner = createThunkFromApiWithType("owners/createOwner", api.createOwner);

export const updateOwner = createThunkFromApiWithType("owners/updateOwner", api.updateOwner);

export const getAllOwnersForExpenses = createThunkFromApiWithType(
  "owners/getAllOwnersForExpenses",
  api.getAllOwnersForExpenses
);

export const deleteOwner = createAsyncThunk(
  "owners/removeOwner",
  async (deleteOwnerArgs, { rejectWithValue }) => {
    try {
      const { id, handleCloseDelete } = deleteOwnerArgs;
      await api.removeOwner(id);

      handleCloseDelete();
      return { id };
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;
        if (status === 401) return rejectWithValue(data.detail);
      }
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const ownersSlice = createSlice({
  name: "owners",
  initialState,
  extraReducers: (reducers) => {
    reducers
      .addCase(getAllOwnersForExpenses.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = "";
        ownerAdapter.setAll(state, payload);
      })

      .addCase(getAllOwners.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(getAllOwners.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = "";
        ownerAdapter.setAll(state, payload);
      })

      .addCase(getAllOwners.rejected, (state, { payload }) => {
        return {
          ...state,
          error: payload?.detail,
        };
      })

      .addCase(getOwner.fulfilled, (state, { payload }) => {
        ownerAdapter.setOne(state, payload);
      })

      .addCase(createOwner.pending, (state) => {
        state.isRequestPending = true;
      })

      .addCase(createOwner.fulfilled, (state, { payload }) => {
        ownerAdapter.addOne(state, payload);
        state.isRequestPending = false;
      })

      .addCase(createOwner.rejected, (state) => {
        state.isRequestPending = false;
      })

      .addCase(updateOwner.pending, (state) => {
        state.isRequestPending = true;
      })

      .addCase(updateOwner.fulfilled, (state, { payload }) => {
        ownerAdapter.updateOne(state, {
          id: payload.id,
          changes: payload,
        });
        state.isRequestPending = false;
      })

      .addCase(updateOwner.rejected, (state) => {
        state.isRequestPending = false;
      })

      .addCase(deleteOwner.pending, (state) => {
        state.isRequestPending = true;
      })

      .addCase(deleteOwner.fulfilled, (state, { payload: { id } }) => {
        ownerAdapter.removeOne(state, id);
        state.isRequestPending = false;
      })

      .addCase(deleteOwner.rejected, (state) => {
        state.isRequestPending = false;
      });
  },
});

export default ownersSlice.reducer;
