import { PageTabs } from "components/common";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectAllPermissions } from "store/myPermissions/myPermissionsSlice";
import { selectOrganization } from "store/organization/organizationSlice";
import { Organization } from "types/Organization";

import HomeAppointmentsTab from "./HomeAppointmentsTab";
import PeepPayLinksTab from "./PeepPayLinksTab";

const HomePage = () => {
  const { t } = useTranslation("home");

  const { hasPeepPay: doesOrgHavePeepPay } = useSelector(selectOrganization) as Organization;
  const { hasPeepPay: doesUserHaveAccessToPeepPay } = useSelector(selectAllPermissions);

  const shouldShowPeepPayTab =
    (doesOrgHavePeepPay && doesUserHaveAccessToPeepPay.editAccess) || !doesOrgHavePeepPay;

  const tabs = [
    {
      id: 0,
      label: t("appointments"),
      component: <HomeAppointmentsTab />,
    },

    ...(shouldShowPeepPayTab
      ? [
          {
            id: 1,
            label: t("peepPayLinks"),
            component: <PeepPayLinksTab doesOrgHavePeepPay={doesOrgHavePeepPay} />,
          },
        ]
      : []),
  ];

  return (
    <PageTabs
      tabs={tabs}
      withContainer={false}
      tabsProps={{
        variant: "scrollable",
        scrollButtons: "auto",
      }}
    />
  );
};

export default HomePage;
