import { Stack } from "@mui/material";
import { PeepPayIcon } from "assets/icon";
import ManagePeepPayModal from "components/common/Modals/ManagePeepPayModal";
import SendPeepPayLinkModal from "components/SendPeepPayLinkModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectAllPermissions } from "store/myPermissions/myPermissionsSlice";
import { selectOrganization } from "store/organization/organizationSlice";
import { Client } from "types/Client";
import { Organization } from "types/Organization";

import { useCheckoutFormContext } from "../CheckoutFormContext";
import CheckoutPaymentMethod from "./CheckoutPaymentMethod";

type PeepPayPaymentMethodProps = {
  isDisabled: boolean;
  client: Client;
};

const PeepPayPaymentMethod = ({ isDisabled, client }: PeepPayPaymentMethodProps) => {
  const { t } = useTranslation(["checkout", "invoices"]);

  const { setValue, watch, hasPendingPayments } = useCheckoutFormContext();

  const { hasPeepPay } = useSelector(selectOrganization) as Organization;

  const { hasPeepPaySendAccess } = useSelector(selectAllPermissions);

  const clientPhoneNumber = {
    phoneNumber: client.phoneNumber,
    phoneCountryCode: client.phoneCountryCode,
  };

  const [peepPayPhoneNumber, setPeepPayPhoneNumber] = useState(clientPhoneNumber);
  const { payments, paymentAmount } = watch();

  const hasExistingPeepPayPaymentMethod = payments.some(
    (payment) => payment.paymentType === null && !!payment.peepPay
  );

  const [isSendPeepPayLinkModalOpen, setIsSendPeepPayLinkModalOpen] = useState(false);
  const [isRequestManagePeepPayModalOpen, setIsRequestManagePeepPayModalOpen] = useState(false);

  const handleOpenRequestManagePeepPayModal = () => setIsRequestManagePeepPayModalOpen(true);

  const handleCloseRequestManagePeepPayModal = () => setIsRequestManagePeepPayModalOpen(false);

  const handleOpenSendPeepPayLinkModal = () => setIsSendPeepPayLinkModalOpen(true);

  const handleCloseSendPeepPayLinkModal = () => setIsSendPeepPayLinkModalOpen(false);

  const handleClickPeepPay = () => {
    if (!hasPeepPay) handleOpenRequestManagePeepPayModal();
    else if (hasPeepPaySendAccess.editAccess) handleOpenSendPeepPayLinkModal();
  };

  const handleClose = () => {
    handleCloseSendPeepPayLinkModal();

    // Reset the phone number to the client's phone number
    setPeepPayPhoneNumber(clientPhoneNumber);
  };

  const handleClickConfirm = () => {
    const newPayment = {
      amount: paymentAmount,
      paymentType: null,
      peepPay: peepPayPhoneNumber,
    };

    setValue("payments", [...payments, newPayment]);

    handleClose();
  };

  return (
    <>
      {isSendPeepPayLinkModalOpen && (
        <SendPeepPayLinkModal
          isOpen={isSendPeepPayLinkModalOpen}
          onClose={handleClose}
          phoneCountryCode={peepPayPhoneNumber.phoneCountryCode}
          phoneNumber={peepPayPhoneNumber.phoneNumber}
          setPeepPayPhoneNumber={setPeepPayPhoneNumber}
          amountToCharge={paymentAmount}
          onConfirmButtonClick={handleClickConfirm}
          shouldDisableConfirmButton={
            hasExistingPeepPayPaymentMethod || isDisabled || hasPendingPayments
          }
          helperText={
            hasExistingPeepPayPaymentMethod || hasPendingPayments
              ? t("checkout:peepPayPaymentMethodAlreadyAdded")
              : ""
          }
        />
      )}

      {isRequestManagePeepPayModalOpen && (
        <ManagePeepPayModal
          isOpen={isRequestManagePeepPayModalOpen}
          onClose={handleCloseRequestManagePeepPayModal}
        />
      )}

      <CheckoutPaymentMethod
        key={t("peepPay")}
        title={
          <Stack direction="row" gap={1} alignItems="center">
            <PeepPayIcon
              viewBox="0 0 56 24"
              sx={{
                width: 56,
                height: 24,
              }}
            />

            {t("invoices:peepPay")}
          </Stack>
        }
        disabled={isDisabled}
        handleClick={handleClickPeepPay}
      />
    </>
  );
};

export default PeepPayPaymentMethod;
