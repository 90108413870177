import { DateTime } from "luxon";

export const today = () =>
  DateTime.now().set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

export const timePeriodOptions = [
  ["today", [today(), today()]],
  ["yesterday", [today().minus({ day: 1 }), today().minus({ day: 1 })]],
  ["lastSevenDays", [today().minus({ day: 6 }), today()]],
  ["lastThirtyDays", [today().minus({ day: 30 }), today()]],
  ["yearToDate", [today().minus({ year: 1 }), today()]],
];

const areDateRangesEqual = ([startDate1, endDate1], [startDate2, endDate2]) =>
  startDate1?.toMillis() === startDate2?.toMillis() &&
  endDate1?.toMillis() === endDate2?.toMillis();

export const matchTimePeriod = (dateRange) => {
  let result = "custom";
  timePeriodOptions.forEach(([key, value]) => {
    if (areDateRangesEqual(dateRange, value)) {
      result = key;
    }
  });

  return result;
};

export const formatDate = (dateTime) => {
  return dateTime?.toFormat("yyyy-MM-dd");
};

export const getOutletLabel = (outletsState, location, t) => {
  if (location === 0) {
    return t("allLocations");
  }
  const foundOutlet = outletsState.find((outlet) => outlet.id === location);
  return foundOutlet?.deprecated ? `${foundOutlet.name} (${t("deleted")})` : foundOutlet.name;
};

export const getDateLabel = (date) => {
  const isSameYear = date[0]?.c?.year === date[1]?.c?.year;
  const isSameMonth = date[0]?.c?.month === date[1]?.c?.month;
  const isSameDay = date[0]?.c?.day === date[1]?.c?.day;
  if (isSameYear && isSameMonth && isSameDay) {
    return date[0]?.toFormat("DDD");
  }

  return `${date[0]?.toFormat("DDD")} - ${date[1]?.toFormat("DDD")} `;
};

export const getShortDateLabel = (date) => {
  const isSameYear = date[0]?.c?.year === date[1]?.c?.year;
  const isSameMonth = date[0]?.c?.month === date[1]?.c?.month;
  const isSameDay = date[0]?.c?.day === date[1]?.c?.day;
  if (isSameYear && isSameMonth && isSameDay) {
    return date[0]?.toFormat("LLL d, yyyy");
  }
  return `${date[0]?.toFormat("LLL d, yyyy")} - ${date[1]?.toFormat("LLL d, yyyy")}`;
};

export const getPercentage = (amount, totalSales, noSales) => {
  if (noSales) {
    return 0;
  }
  const percentage = ((amount / totalSales) * 100).toFixed(2);
  return isNaN(percentage) ? 0 : percentage;
};

export const colors = [
  "rgba(255, 99, 132)",
  "rgba(54, 162, 235)",
  "rgba(255, 206, 86)",
  "rgba(75, 192, 192)",
  "rgba(153, 102, 255)",
  "rgba(255, 159, 64)",
];

export const titleCaseText = (string) => {
  const result = string.replace(/([A-Z])/g, " $1");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};
