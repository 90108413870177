import { Box, Grid, Typography } from "@mui/material";
import useCheckoutModal from "hooks/useCheckoutModal";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectOrganization } from "store/organization/organizationSlice";
import { selectOutletById } from "store/outlets/outletsSlice";
import { selectInvoiceById } from "store/slices/checkoutInvoices/checkoutInvoicesSelector";
import { Invoice, InvoiceStatus, InvoiceType } from "types/Checkout";
import { Organization } from "types/Organization";

import { useCheckoutFormContext } from "../CheckoutFormContext";
import InvoiceHeaderRowItem from "./InvoiceHeaderRowItem";

type InvoiceHeaderSectionProps = {
  invoice: Invoice;
};

const InvoiceHeaderSection = ({ invoice }: InvoiceHeaderSectionProps) => {
  const { t } = useTranslation(["invoices"]);

  const { setInvoiceId } = useCheckoutModal();

  const { originalInvoiceId, refundInvoiceId } = useCheckoutFormContext();
  const originalInvoice = useSelector(selectInvoiceById(originalInvoiceId || -1));

  const refundInvoice = useSelector(selectInvoiceById(refundInvoiceId || -1));

  const organization = useSelector(selectOrganization) as Organization;
  const outlet = useSelector(selectOutletById(invoice?.outlet?.id));
  const outletAddress = `${invoice.outlet.name} - ${outlet?.address?.area}`;

  const isInvoiceVoided = invoice.status === InvoiceStatus.Void;
  const isRefundInvoice = invoice.type === InvoiceType.Refund;

  // An invoice that has been refunded is considered an original invoice
  const isOriginalInvoice = invoice?.refundInvoice;
  const shouldShowInvoiceStatus = isInvoiceVoided || isRefundInvoice;

  const getInvoiceStatusTitle = () => {
    if (isInvoiceVoided) return t("invoices:voidStatus");
    else if (isRefundInvoice) return t("invoices:refundStatus");
    else return "";
  };

  return (
    <Grid container item xs={12} gap={2}>
      <Grid item xs={12} mb={1}>
        <Typography variant="h2" align="center">
          {organization.name}
        </Typography>

        <Typography variant="secondary" color="gray" align="center" component="p">
          {outletAddress}
        </Typography>

        {shouldShowInvoiceStatus && (
          <Typography variant="h4" color="error" align="center" pt={2}>
            {getInvoiceStatusTitle().toUpperCase()}
          </Typography>
        )}
      </Grid>

      <InvoiceHeaderRowItem titleTranslation="invoices:invoice" value={`#${invoice.number}`} />

      {isRefundInvoice && originalInvoiceId && originalInvoice && (
        <InvoiceHeaderRowItem
          titleTranslation="invoices:originalInvoice"
          value={
            <Typography
              variant="h5"
              color="primary"
              component={Box}
              sx={{
                cursor: "pointer",
              }}
              onClick={() => setInvoiceId(originalInvoiceId)}
            >
              {`#${originalInvoice.number}`}
            </Typography>
          }
        />
      )}

      {isOriginalInvoice && refundInvoiceId && refundInvoice && (
        <InvoiceHeaderRowItem
          titleTranslation="invoices:refundInvoice"
          value={
            <Typography
              variant="h5"
              color="primary"
              component={Box}
              sx={{
                cursor: "pointer",
              }}
              onClick={() => setInvoiceId(refundInvoiceId)}
            >
              {`#${refundInvoice.number}`}
            </Typography>
          }
        />
      )}

      <InvoiceHeaderRowItem
        titleTranslation="translation:date"
        value={DateTime.fromISO(invoice.createdAt).toFormat("dd.MM.yyyy hh:mm a")}
      />
      <InvoiceHeaderRowItem titleTranslation="invoices:customer" value={invoice.clientFullName} />
      <InvoiceHeaderRowItem titleTranslation="invoices:telNo" value={invoice.clientPhoneNumber} />
    </Grid>
  );
};

export default InvoiceHeaderSection;
