import type { RootState } from "store";

import { SliceStatus } from "../utils";

export const selectProductSalesData = (state: RootState) => state.purchasableSales.productSales;

export const selectSubscriptionSalesData = (state: RootState) =>
  state.purchasableSales.subscriptionSales;

export const selectVoucherSalesData = (state: RootState) => state.purchasableSales.voucherSales;

export const selectPeepGiftCreditSalesData = (state: RootState) =>
  state.purchasableSales.peepGiftCreditSales;

export const selectSalesLoading = (state: RootState) =>
  state.purchasableSales.status === SliceStatus.LOADING;

export const selectDailySalesData = (state: RootState) => state.purchasableSales.dailySales;

export const selectRefundsLogData = (state: RootState) => state.purchasableSales.refundsLog;
