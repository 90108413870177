import { Box, FormHelperText } from "@mui/material";
import ClientSection from "components/ClientSection";
import { NewClient } from "components/ClientSection/ClientSection";
import useCheckoutModal from "hooks/useCheckoutModal";
import useFormValidation from "hooks/useFormValidation";
import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectActiveSubscriptionByClientIdAndOutletId } from "store/clients/clientsSlice";
import { CheckoutModalView } from "store/slices/checkout/checkoutSlice";
import { ProcessedSubscriptionPurchase } from "types/Subscription";
import { isRedeemableSubscriptionItemOption } from "types/typeGuards/SubscriptionTypeGuards";

import { CheckoutArticle, useCheckoutFormContext } from "./CheckoutFormContext";
import {
  convertPurchasableItemTypeToRedeemableSubscriptionItemOption,
  getDefaultRedeemableSubscription,
} from "./RedeemSubscriptionsUtils";

const CheckoutClientSection = () => {
  const { t } = useTranslation("checkout");

  const { appointmentId, checkoutModalView } = useCheckoutModal();

  const disableChangeClient =
    !!appointmentId ||
    (checkoutModalView !== CheckoutModalView.AddItems &&
      checkoutModalView !== CheckoutModalView.Cart);

  const { watch, setValue, formState, balance } = useCheckoutFormContext();

  const registerValidation = useFormValidation(formState);

  const { newClient, existingClient, outlet, articles } = watch();
  const existingClientId = existingClient?.id || null;

  const clientIdRef = useRef<Nullable<number>>(null);

  const clientActiveSubscriptions = useSelector(
    selectActiveSubscriptionByClientIdAndOutletId(existingClientId, outlet)
  ) as ProcessedSubscriptionPurchase[];

  /**
   * Update article subscription redemption if client is changed, based on the new client's active subscriptions
   */
  const updateCartAppliedSubscriptions = useCallback(() => {
    articles.forEach((article, index) => {
      const {
        purchasableItemType,
        purchasedItem,
        appointmentArticleId,
        subscriptionPurchaseId,
        subscriptionRedemptionId,
        finalPrice,
        promotionId,
        rewardLoyaltyCard,
      } = article;

      const wouldApplyingRedemptionRequireRefunding = balance - finalPrice < 0;

      // TODO: MAKE SURE THIS CONDITION should include !subscriptionRedemptionId
      const canApplyAutoSubscriptionRedemption =
        !promotionId && !subscriptionRedemptionId && !rewardLoyaltyCard;

      if (canApplyAutoSubscriptionRedemption) {
        if (appointmentArticleId && subscriptionPurchaseId && subscriptionRedemptionId) {
          setValue(`articles.${index}.finalPrice`, 0);
          setValue(`articles.${index}.subscriptionPurchaseId`, subscriptionPurchaseId);
        } else {
          // if balance is 0 or applying redemption would require refunding, do not apply redemption
          if (balance <= 0 || wouldApplyingRedemptionRequireRefunding) {
            return;
          }
          const redemptionItemType =
            convertPurchasableItemTypeToRedeemableSubscriptionItemOption(purchasableItemType);

          const purchasableItemId = redemptionItemType
            ? purchasedItem[
                redemptionItemType === "serviceVariants" ? "serviceVariant" : redemptionItemType
              ]
            : null;

          const subscriptionIdToBeApplied =
            purchasableItemId && isRedeemableSubscriptionItemOption(redemptionItemType)
              ? getDefaultRedeemableSubscription(
                  articles,
                  clientActiveSubscriptions,
                  purchasableItemId,
                  redemptionItemType
                )
              : null;

          if (subscriptionIdToBeApplied) {
            setValue(`articles.${index}.finalPrice`, 0);
            setValue(`articles.${index}.subscriptionPurchaseId`, subscriptionIdToBeApplied);
          }
        }
      }
    });
  }, [articles, clientActiveSubscriptions, setValue, balance]);

  useEffect(() => {
    if (clientIdRef.current !== existingClientId && clientActiveSubscriptions.length) {
      updateCartAppliedSubscriptions();

      clientIdRef.current = existingClientId;
    } else if (!existingClientId) clientIdRef.current = null;
  }, [clientActiveSubscriptions, existingClientId, updateCartAppliedSubscriptions]);

  const setClientId = (clientId: Nullable<number>) => {
    if (clientId) {
      setValue("existingClient.id", clientId);

      resetAppliedVoucherRedemptions();
    } else setValue("existingClient", null);
  };

  const setNewClient = (newClient: Nullable<NewClient>) => {
    setValue("newClient", newClient);

    resetAppliedSubscriptions(articles);

    resetAppliedVoucherRedemptions();
  };

  /**
   * reset all articles to their original price if they have applied subscriptions, used when client is changed.
   * @param articles
   */

  const resetAppliedSubscriptions = (articles: CheckoutArticle[]) => {
    articles.forEach((article, index) => {
      if (article.subscriptionPurchaseId) {
        setValue(`articles.${index}.finalPrice`, article.originalPrice);
        setValue(`articles.${index}.subscriptionPurchaseId`, null);
      }
    });
  };

  const resetAppliedVoucherRedemptions = () => {
    setValue("voucherRedemptions", []);
  };

  const hasClient = !!existingClientId || !!newClient;
  const shouldShowNoClient = !hasClient && disableChangeClient;

  const isAnAppointmentWithWalkInClient = !!appointmentId && !hasClient;

  const shouldShowClientIsRequiredToSellError =
    shouldShowNoClient &&
    isAnAppointmentWithWalkInClient &&
    registerValidation("isClientRequired").error;

  return (
    <Box p={3} boxShadow="divider">
      <ClientSection
        allowedToChangeClient={!disableChangeClient}
        existingClientId={existingClientId || null}
        setExistingClientId={setClientId}
        newClient={newClient}
        setNewClient={setNewClient}
        outletId={outlet}
        shouldShowNoClient={shouldShowNoClient}
        {...registerValidation("isClientRequired")}
        shouldTriggerValidation={formState.isSubmitting}
      />

      {shouldShowClientIsRequiredToSellError && (
        <FormHelperText error>{`${t(
          "youMustEditTheAppointmentAndSetTheClientToCompleteTheSale"
        )}. ${registerValidation("isClientRequired").helperText}`}</FormHelperText>
      )}
    </Box>
  );
};

export default CheckoutClientSection;
