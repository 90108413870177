import { Stack, Typography } from "@mui/material";
import { DepositIcon, PeepPayIcon } from "assets/icon";
import { ActionChip } from "components/common";
import getFormattedPrice from "helpers/getFormattedPrice";
import { camelize } from "humps";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectAppointmentById } from "store/appointments/appointmentsSlice";
import { selectAllPermissions } from "store/myPermissions/myPermissionsSlice";
import { selectOrganization } from "store/organization/organizationSlice";
import { selectDepositById } from "store/selectors";
import { Appointment } from "types/Appointment";
import { PeepPayLinkStatus } from "types/Deposit";
import { Organization } from "types/Organization";

import CancelPeepPayLinkModal from "./CancelPeepPayLinkModal";
import RemoveDepositModal from "./RemoveDepositModal";

type ExistingDepositChipProps = {
  depositId: number;
};

const ExistingDepositChip = ({ depositId }: ExistingDepositChipProps) => {
  const { t } = useTranslation("deposits");
  const { hasPeepPay } = useSelector(selectOrganization) as Organization;

  const [isRemoveDepositModalOpen, setIsRemoveDepositModalOpen] = useState<boolean>(false);
  const [isCancelPeepPaymentLinkModalOpen, setIsCancelPeepPaymentLinkModalOpen] = useState(false);

  const { hasDeposits, hasPeepPayCancelAccess } = useSelector(selectAllPermissions);

  const deposit = useSelector(selectDepositById(depositId));

  const peepPayLink = deposit?.peepPay;

  const hasPeepPayLink = !!peepPayLink;

  const appointment = useSelector(selectAppointmentById(deposit?.appointment)) as Appointment;

  const isPeepPayLinkStatusCancellable =
    peepPayLink?.status === PeepPayLinkStatus.Pending ||
    peepPayLink?.status === PeepPayLinkStatus.Expired;

  const isPeepPayLinkExpired = peepPayLink?.status === PeepPayLinkStatus.Expired;

  const canCancelPeepPayLink =
    hasPeepPay &&
    hasPeepPayLink &&
    hasPeepPayCancelAccess.editAccess &&
    isPeepPayLinkStatusCancellable;

  const hasRemovePermission = hasPeepPayLink ? canCancelPeepPayLink : hasDeposits.editAccess;

  const handleRemoveClick = () => {
    if (hasPeepPayLink && isPeepPayLinkStatusCancellable) setIsCancelPeepPaymentLinkModalOpen(true);
    else {
      setIsRemoveDepositModalOpen(true);
    }
  };

  if (!deposit || !appointment) return null;

  return (
    <>
      <ActionChip
        icon={<DepositIcon fontSize="small" sx={{ mr: 1, fill: "#4048D6", stroke: "#4048D6" }} />}
        title={
          <Typography variant="secondary" fontWeight={700}>
            <Trans
              i18nKey="deposits:depositAdded"
              values={{ amount: `${t("currency")} ${getFormattedPrice(deposit.amount)}` }}
              components={[
                <Typography
                  key="amount-value"
                  display="inline"
                  fontWeight={700}
                  color="primary"
                  variant="secondary"
                />,
              ]}
            />
          </Typography>
        }
        secondTitle={
          hasPeepPayLink && (
            <Stack direction="row" gap={0.5} alignItems="center">
              <PeepPayIcon
                viewBox="0 0 56 24"
                sx={{
                  width: 56,
                  height: 18,
                }}
              />
              <Typography
                variant="secondary"
                fontWeight={500}
                color={isPeepPayLinkExpired ? "error.main" : "text.primary"}
              >
                {t(`${camelize(deposit.peepPay?.status.toLowerCase() || "")}`)}
              </Typography>
            </Stack>
          )
        }
        removeActionTitle={hasPeepPayLink ? null : t("remove")}
        handleRemoveAction={handleRemoveClick}
        hasAppliedAction
        shouldShowAction={hasRemovePermission}
        hasPermission={hasRemovePermission}
        gap={0}
      />

      {canCancelPeepPayLink && peepPayLink && (
        <CancelPeepPayLinkModal
          isOpen={isCancelPeepPaymentLinkModalOpen}
          onClose={() => setIsCancelPeepPaymentLinkModalOpen(false)}
          depositId={depositId}
          clientId={appointment.client}
        />
      )}

      <RemoveDepositModal
        clientId={appointment.client}
        depositId={depositId}
        isRemoveDepositModalOpen={isRemoveDepositModalOpen}
        setIsRemoveDepositModalOpen={setIsRemoveDepositModalOpen}
      />
    </>
  );
};

export default ExistingDepositChip;
