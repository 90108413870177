import { Box, Stack, Typography } from "@mui/material";
import { LocationPinIcon } from "assets/icon";
import AreaAutocomplete from "components/AreaAutocomplete";
import ClientAddresses from "components/Clients/ClientProfile/ClientHistory/ClientAddresses";
import useFormValidation from "hooks/useFormValidation";
import CalendarPageContext from "pages/CalendarPage/CalendarPageContext";
import { useCallback, useContext, useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectClientAddressByClientIdAndAddressId } from "store/clients/clientSelectors";
import { selectClientById } from "store/clients/clientsSlice";
import { selectAppointmentFormEmployeesByOutletIdAndZoneId } from "store/employees/employeesSlice";
import { selectAreaById } from "store/slices/areas/areasSelector";
import { selectZoneById } from "store/slices/zones/zonesSelectors";
import { Client } from "types/Client";

import { AppointmentFormValues } from "../utils";
import AppointmentFormSectionActionButton from "./AppointmentFormSectionActionButton";
import HomeServiceAppointmentFormSection from "./HomeServiceAppointmentFormSection";

type HomeServiceAppointmentFormAreaSectionProps = {
  isExpanded: boolean;
  isDisabled: boolean;
  handleModifyStepClick: () => void;
};

const HomeServiceAppointmentFormAreaSection = ({
  isDisabled,
  isExpanded,
  handleModifyStepClick,
}: HomeServiceAppointmentFormAreaSectionProps) => {
  const { t } = useTranslation(["appointments", "translation"]);

  const hasResetEmployeesForArea = useRef<number | undefined>(undefined);

  const { setValue, watch, formState } = useFormContext<AppointmentFormValues>();
  const registerValidation = useFormValidation(formState, { ns: "client" });
  const { outletId } = useContext(CalendarPageContext);

  const {
    area: areaId,
    client: { id: clientId },
    address: addressId,
    articles,
  } = watch();

  const clientAddress = useSelector(
    selectClientAddressByClientIdAndAddressId(clientId || 0, addressId || 0)
  );

  const area = useSelector(selectAreaById(areaId || clientAddress?.area || 0));

  const zone = useSelector(selectZoneById(area?.zone || 0));

  const employees = useSelector(
    selectAppointmentFormEmployeesByOutletIdAndZoneId(outletId, zone?.id || 0)
  );

  const resetStaffOnArticleIfZoneChanged = useCallback(() => {
    articles.forEach((article, articleIndex) => {
      article.slots.forEach((slot, slotIndex) => {
        if (slot.staffId && !employees.find((employee) => employee.id === slot.staffId)) {
          const slotStaffPath: `articles.${number}.slots.${number}.staffId` = `articles.${articleIndex}.slots.${slotIndex}.staffId`;
          setValue(slotStaffPath, null);
        }
      });
    });
  }, [articles, employees, setValue]);

  useEffect(() => {
    if (area && hasResetEmployeesForArea.current !== area.id) {
      hasResetEmployeesForArea.current = area.id;
      resetStaffOnArticleIfZoneChanged();
    }
  }, [area, resetStaffOnArticleIfZoneChanged]);

  const client = useSelector(selectClientById(clientId)) as Client | undefined;
  const { addresses = [] } = client || {};

  const shouldDisplaySubtitle = !!area && !isExpanded;

  const handleChangeArea = (newValue: Nullable<number>) => {
    if (newValue) {
      setValue("area", newValue);
      setValue("address", null);
    } else setValue("area", null);
  };

  const handleSelectAddress = (newAddressValue: Nullable<number>) => {
    if (newAddressValue === addressId) {
      setValue("address", null);

      return;
    }

    const addressArea = addresses.find((address) => address.id === newAddressValue)?.area;

    setValue("area", addressArea || null);
    setValue("address", newAddressValue);
  };

  const hasClient = !!clientId;

  const handleModifyAreaClick = () => {
    handleModifyStepClick();
  };

  return (
    <HomeServiceAppointmentFormSection
      title={t("area")}
      subtitle={
        <Typography variant="primary" fontSize="0.875" fontWeight={700}>
          {shouldDisplaySubtitle ? (
            <>
              {area?.name}

              <Typography variant="secondary" color="text.placeholder" fontWeight={500} px={0.5}>
                {`${zone?.name}` || "-"}
              </Typography>
            </>
          ) : (
            ""
          )}
        </Typography>
      }
      icon={<LocationPinIcon />}
      isDisabled={isDisabled}
      actionButton={
        !isExpanded &&
        areaId && (
          <AppointmentFormSectionActionButton onClick={handleModifyAreaClick}>
            {t("appointments:modify")}
          </AppointmentFormSectionActionButton>
        )
      }
      children={
        isExpanded ? (
          <Stack gap={2}>
            <Box bgcolor="bg.secondary" borderRadius={2}>
              <AreaAutocomplete
                label={t("area")}
                {...registerValidation("area", t("area"))}
                value={watch("area")}
                handleSelectValue={handleChangeArea}
                shouldGroupOptionsByZone
                shouldShowOnlyAreasWithZone
              />
            </Box>

            {hasClient && (
              <Box>
                <ClientAddresses
                  clientId={clientId}
                  title={t("appointments:savedAddresses")}
                  hasRadioButton
                  showAreaStatusChip
                  handleToggleSelection={handleSelectAddress}
                  addressId={addressId}
                  shouldShowManageButtons={false}
                  shouldShowAddAddressButton={false}
                  shouldShowEmptyGraphic={false}
                />
              </Box>
            )}
          </Stack>
        ) : null
      }
    />
  );
};

export default HomeServiceAppointmentFormAreaSection;
