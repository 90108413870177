import { createSlice } from "@reduxjs/toolkit";
import downloadResponse from "store/sales/downloadResponse";
import { SliceStatus } from "store/slices/utils";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { PaymentTransactionLog, TransactionsDashboardData, WalletTransactionLog } from "types/Transactions";

import api, { PaginatedResponseType } from "./transactionsApi";

type TransactionsSliceState = {
  walletTransactionLogs: PaginatedResponseType & { results: WalletTransactionLog[] };
  paymentTransactionLogs: PaginatedResponseType & { results: PaymentTransactionLog[] };
  dashboard?: TransactionsDashboardData;

  status: SliceStatus;
};

const initialState: TransactionsSliceState = {
  walletTransactionLogs: {
    count: 0,
    next: "",
    previous: "",
    results: [],
    totalPages: 0,
  },
  paymentTransactionLogs: {
    count: 0,
    next: "",
    previous: "",
    results: [],
    totalPages: 0,
  },
  dashboard: undefined,
  status: SliceStatus.IDLE,
};

export const getTransactionsDashboardData = createThunkFromApiWithType(
  "transactions/getTransactionsDashboardData",
  api.getTransactionsDashboardData
);

export const getWalletTransactionLogs = createThunkFromApiWithType(
  "transactions/salesWalletTransactionLogs/",
  api.getWalletTransactionLogs
);


export const appendWalletTransactionLogs = createThunkFromApiWithType(
  "transactions/appendWalletTransactionLogs/",
  api.getWalletTransactionLogs
);

export const getWalletTransactionLogsExcel = createThunkFromApiWithType(
  "transactions/getWalletTransactionLogsExcel",
  api.getWalletTransactionLogsExcel,
  {
    handleResponse: ({ response }) => {
      downloadResponse(response);
    },
  }
);

export const getPaymentTransactionLogs = createThunkFromApiWithType(
  "transactions/salesPaymentTransactionLogs/",
  api.getPaymentTransactionLogs
);


export const appendPaymentTransactionLogs = createThunkFromApiWithType(
  "transactions/appendPaymentTransactionLogs/",
  api.getPaymentTransactionLogs
);

export const getPaymentTransactionLogsExcel = createThunkFromApiWithType(
  "transactions/getPaymentTransactionLogsExcel",
  api.getPaymentTransactionLogsExcel,
  {
    handleResponse: ({ response }) => {
      downloadResponse(response);
    },
  }
);

export const transactionLogsSlice = createSlice({
  name: "transactions",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    // Dashboard Data REDUCERS
    reducers.addCase(getTransactionsDashboardData.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });
    reducers.addCase(getTransactionsDashboardData.fulfilled, (state, { payload }) => {
      state.dashboard = payload;
      state.status = SliceStatus.IDLE;
    });
    reducers.addCase(getTransactionsDashboardData.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });
    // Wallet Transaction LOGs REDUCERS
    reducers.addCase(getWalletTransactionLogs.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });
    reducers.addCase(getWalletTransactionLogs.fulfilled, (state, { payload }) => {
      state.walletTransactionLogs = payload;
      state.status = SliceStatus.IDLE;
    });
    reducers.addCase(getWalletTransactionLogs.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    reducers.addCase(appendWalletTransactionLogs.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });
    reducers.addCase(appendWalletTransactionLogs.fulfilled, (state, { payload }) => {
      state.status = SliceStatus.IDLE;
      state.walletTransactionLogs.results = [
        ...state.walletTransactionLogs.results,
        ...payload.results,
      ];
    });
    reducers.addCase(appendWalletTransactionLogs.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(getWalletTransactionLogsExcel.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });
    reducers.addCase(getWalletTransactionLogsExcel.fulfilled, (state) => {
      state.status = SliceStatus.IDLE;
    });
    reducers.addCase(getWalletTransactionLogsExcel.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });
    // Payment Transaction LOGs REDUCERS
    reducers.addCase(getPaymentTransactionLogs.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });
    reducers.addCase(getPaymentTransactionLogs.fulfilled, (state, { payload }) => {
      state.paymentTransactionLogs = payload;
      state.status = SliceStatus.IDLE;
    });
    reducers.addCase(getPaymentTransactionLogs.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });
    reducers.addCase(appendPaymentTransactionLogs.pending, (state) => {
      state.status = SliceStatus.LOADING;
    }
    );
    reducers.addCase(appendPaymentTransactionLogs.fulfilled, (state, { payload }) => {
      state.status = SliceStatus.IDLE;
      state.paymentTransactionLogs.results = [
        ...state.paymentTransactionLogs.results,
        ...payload.results,
      ];
    });
    reducers.addCase(appendPaymentTransactionLogs.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });
    reducers.addCase(getPaymentTransactionLogsExcel.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });
    reducers.addCase(getPaymentTransactionLogsExcel.fulfilled, (state) => {
      state.status = SliceStatus.IDLE;
    });
    reducers.addCase(getPaymentTransactionLogsExcel.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    }
    );

  },
});

export default transactionLogsSlice.reducer;
