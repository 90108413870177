import { Area } from "types/Area";
import Employee from "types/Employee";
import { InvoicePurchasableItemType } from "types/Invoice";
import { InvoiceCreationSource, RefundLog } from "types/Sales";
import { ServiceVariantWithService } from "types/ServiceV2";
import { Zone } from "types/Zone";
import http from "utils/http";

function stringifyArray(array: string[]) {
  return array.length < 1 ? "" : array.toString();
}

export type PurchasableSalesResponseType = {
  count: number;
  next: string;
  previous: string;
  totalSales: number;
  totalPages: number;
};

export type ProductPerformanceResponseType = {
  count: number;
  product: Product;
  total: string;
  refundTotal: string;
  refundCount: string;
};

export type EmployeePerformanceResponseType = {
  count: number;
  employee: Employee;
  total: string;
  serviceRefundCount: string;
  refundTotal: string;
  serviceRefundTotal: string;
  productTotal: string;
  subscriptionTotal: string;
  voucherTotal: string;
};

export type ServiceVariantPerformanceResponseType = {
  count: number;
  serviceVariant: ServiceVariantWithService;
  total: string;
  refundTotal: string;
  refundCount: string;
};

export type AreaPerformanceResponseType = {
  area: Area;
  appointmentCount: number;
  totalSales: string;
  totalRefunds: string;
};

export type ZonePerformanceResponseType = {
  zone: Zone;
  appointmentCount: number;
  totalSales: string;
  totalRefunds: string;
};

export type RefundLogsParamsType = {
  page: number;
  startDate: string;
  endDate: string;
  outlets?: number[];
  createdBy?: number[];
  purchasableItemTypes?: InvoicePurchasableItemType[];
  invoiceCreationMethods?: InvoiceCreationSource[];
  search?: string;
  ordering?: string;
};

export type RefundLogsResponseType = Omit<PurchasableSalesResponseType, "totalSales"> & {
  results: RefundLog[];
};

const salesApi = {
  getProductSalesData: async ({
    page,
    startDate,
    endDate,
    outlets,
    employees,
    products,
    categories,
    ordering,
  }: {
    page: number;
    startDate?: string;
    endDate?: string;
    outlets: number[];
    employees: number[];
    products: number[];
    categories: number[];
    ordering?: string;
  }) =>
    http.get(
      `sales/sales_products/?page=${page}&start_date=${startDate}&end_date=${endDate}&outlets=${stringifyArray(
        outlets.join().split(",")
      )}&employees=${stringifyArray(employees.join().split(","))}&products=${stringifyArray(
        products.join().split(",")
      )}&categories=${stringifyArray(categories.join().split(","))}${
        ordering ? `&order_by=${ordering}` : ""
      }`
    ),

  getProductSalesExcelData: async ({
    startDate,
    endDate,
    outlets,
    employees,
    products,
    categories,
  }: {
    startDate?: string;
    endDate?: string;
    outlets: number[];
    employees: number[];
    products: number[];
    categories: number[];
  }) =>
    http.get<ArrayBuffer>(
      `sales/sales_products/?page=1&start_date=${startDate}&end_date=${endDate}${
        outlets && !!outlets.length ? `&outlets=${stringifyArray(outlets.join().split(","))}` : ""
      }${
        employees && !!employees.length
          ? `&employees=${stringifyArray(employees.join().split(","))}`
          : ""
      }${
        products && !!products.length
          ? `&products=${stringifyArray(products.join().split(","))}`
          : ""
      }${
        categories && !!categories.length
          ? `&categories=${stringifyArray(categories.join().split(","))}`
          : ""
      }&excel_download=True`,
      {
        responseType: "arraybuffer",
        transformRequest: [],
        transformResponse: [],
      }
    ),

  getSubscriptionSalesData: async ({
    page,
    startDate,
    endDate,
    outlets,
    employees,
  }: {
    page: number;
    startDate?: string;
    endDate?: string;
    outlets: number[];
    employees: number[];
  }) =>
    http.get(
      `sales/sales_subscriptions/?page=${page}&start_date=${startDate}&end_date=${endDate}${
        outlets && !!outlets.length ? `&outlets=${stringifyArray(outlets.join().split(","))}` : ""
      }${
        employees && !!employees.length
          ? `&employees=${stringifyArray(employees.join().split(","))}`
          : ""
      }`
    ),

  getSubscriptionSalesExcelData: async ({
    startDate,
    endDate,
    outlets,
    employees,
  }: {
    startDate?: string;
    endDate?: string;
    outlets: number[];
    employees: number[];
  }) =>
    http.get(
      `sales/sales_subscriptions/?page=1&start_date=${startDate}&end_date=${endDate}${
        outlets && !!outlets.length ? `&outlets=${stringifyArray(outlets.join().split(","))}` : ""
      }${
        employees && !!employees.length
          ? `&employees=${stringifyArray(employees.join().split(","))}`
          : ""
      }&excel_download=true`,
      {
        responseType: "arraybuffer",
        transformRequest: [],
        transformResponse: [],
      }
    ),

  getVoucherSalesExcelData: async ({
    startDate,
    endDate,
    outlets,
    employees,
  }: {
    startDate?: string;
    endDate?: string;
    outlets: number[];
    employees: number[];
  }) =>
    http.get(
      `sales/sales_vouchers/?page=1&start_date=${startDate}&end_date=${endDate}${
        outlets && !!outlets.length ? `&outlets=${stringifyArray(outlets.join().split(","))}` : ""
      }${
        employees && !!employees.length
          ? `&employees=${stringifyArray(employees.join().split(","))}`
          : ""
      }&excel_download=true`,
      {
        responseType: "arraybuffer",
        transformRequest: [],
        transformResponse: [],
      }
    ),

  getDailySales: async ({ outlet }: { outlet?: Nullable<string> }) =>
    http.get(`sales/sales_daily/${outlet ? `?outlet=${outlet}` : ""}`),

  getVoucherSalesData: async ({
    page,
    startDate,
    endDate,
    outlets,
    employees,
    ordering,
  }: {
    page: number;
    startDate?: string;
    endDate?: string;
    outlets: number[];
    employees: number[];
    ordering: string;
  }) =>
    http.get(
      `sales/sales_vouchers/?page=${page}&start_date=${startDate}&end_date=${endDate}&outlets=${stringifyArray(
        outlets.join().split(",")
      )}&employees=${stringifyArray(employees.join().split(","))}&order_by=${ordering}`
    ),

  getPeepGiftCardSalesData: async ({
    page,
    startDate,
    endDate,
    ordering,
    searchQuery,
  }: {
    page: number;
    startDate?: string;
    endDate?: string;
    ordering: string;
    searchQuery?: string;
  }) =>
    http.get(
      `sales/sales_peep_gift_credits/?page=${page}&start_date=${startDate}&end_date=${endDate}${
        searchQuery ? `&search=${searchQuery}` : ""
      }&order_by=${ordering}`
    ),

  getRefundsLog: async ({
    page,
    startDate,
    endDate,
    outlets,
    createdBy,
    purchasableItemTypes,
    invoiceCreationMethods,
    search,
    ordering,
  }: RefundLogsParamsType) => {
    const outletsParam = outlets ? `&outlets=${stringifyArray(outlets.join().split(","))}` : "";
    const createdByParam = createdBy
      ? `created_by=${stringifyArray(createdBy.join().split(","))}`
      : "";
    const searchParam = search ? `&search=${search}` : "";
    const orderingParam = ordering ? `&order_by=${ordering}` : "";
    const purchasableItemTypeParam = purchasableItemTypes
      ? `&purchasable_item_type=${stringifyArray(purchasableItemTypes.join().split(","))}`
      : "";
    const invoiceCreationMethodParam = invoiceCreationMethods
      ? `&invoice_creation_method=${stringifyArray(invoiceCreationMethods.join().split(","))}`
      : "";

    return http.get(
      `sales/refund_logs/?page=${page}&start_date=${startDate}&end_date=${endDate}&${outletsParam}&${createdByParam}${purchasableItemTypeParam}${invoiceCreationMethodParam}${searchParam}${orderingParam}`
    );
  },

  getRefundsLogExcel: async ({
    startDate,
    endDate,
    outlets,
    createdBy,
    purchasableItemTypes,
    invoiceCreationMethods,
    search,
    ordering,
  }: Omit<RefundLogsParamsType, "page">) => {
    const outletsParam = outlets ? `&outlets=${stringifyArray(outlets.join().split(","))}` : "";
    const createdByParam = createdBy
      ? `created_by=${stringifyArray(createdBy.join().split(","))}`
      : "";
    const searchParam = search ? `&search=${search}` : "";
    const orderingParam = ordering ? `&order_by=${ordering}` : "";
    const purchasableItemTypeParam = purchasableItemTypes
      ? `&purchasable_item_type=${stringifyArray(purchasableItemTypes.join().split(","))}`
      : "";
    const invoiceCreationMethodParam = invoiceCreationMethods
      ? `&invoice_creation_method=${stringifyArray(invoiceCreationMethods.join().split(","))}`
      : "";

    return http.get<ArrayBuffer>(
      `sales/refund_logs/?page=1&start_date=${startDate}&end_date=${endDate}&${outletsParam}&${createdByParam}${purchasableItemTypeParam}${invoiceCreationMethodParam}${searchParam}${orderingParam}&excel_download=True`,
      {
        responseType: "arraybuffer",
        transformRequest: [],
        transformResponse: [],
      }
    );
  },
};

export default salesApi;
