import {
  Calendar,
  FramedUser,
  Home as HomeIcon,
  Report,
  Ticket,
  UserFile,
  UserReading,
  Wrenches,
} from "@bookpeep/ui";
import { Inventory, ResourcesIcon } from "assets/icon";
import CentredSpinnerBox from "components/common/CentredSpinnerBox";
import AuthLayout from "layouts/AuthLayout";
import NavigationMenuLayout from "layouts/NavigationMenuLayout";
import Login from "pages/Login";
import { ReactNode, Suspense, lazy } from "react";
import { RouteObject } from "react-router-dom";
import { PermissionAccess } from "store/myPermissions/utils";
import { ACCESS_TOKEN } from "utils/auth";

import { HomePage } from "./HomePage";

const TransactionsLogs = lazy(() => import("./TransactionsLogs"));
const AccountsSettings = lazy(() => import("./Settings/AccountsSettings"));
const HomeServiceSettings = lazy(() => import("./Settings/HomeServiceSettings"));
const CalendarPage = lazy(() => import("./CalendarPage"));
const ClientProfile = lazy(() => import("./Clients/ClientProfile"));
const Clients = lazy(() => import("./Clients"));
const Expenses = lazy(() => import("./Expenses"));
const ClientWallets = lazy(() => import("./ClientWallets"));
const ForgotPassword = lazy(() => import("components/ForgotPassword"));
const InventoryPage = lazy(() => import("./Inventory/InventoryPage"));
const InvoicePage = lazy(() => import("./InvoicePage"));
const InvoiceSequencing = lazy(() => import("./Settings/InvoiceSequencing"));
const NotFoundPage = lazy(() => import("./404"));
const Offers = lazy(() => import("./Offers"));
const OnlineBooking = lazy(() => import("./Settings/OnlineBooking"));
const PaymentTypes = lazy(() => import("./Settings/PaymentTypes"));
const PeepSubscriptions = lazy(() => import("./Settings/PeepSubscriptions"));
const Permissions = lazy(() => import("./Settings/Permissions"));
const ProductDetailPage = lazy(() => import("./Inventory/Products/ProductDetailPage"));
const Profile = lazy(() => import("components/Profile"));
const ResetPassword = lazy(() => import("components/ResetPassword"));
const Resources = lazy(() => import("./Resources"));
const Sales = lazy(() => import("./Sales"));
const ServicesPage = lazy(() => import("./ServicesPage"));
const Settings = lazy(() => import("./Settings"));
const SMSReminders = lazy(() => import("./Settings/SMSReminders"));
const Staff = lazy(() => import("./Staff"));

declare module "react-router-dom" {
  interface RouteObject {
    paths?: string[];
    title?: string;
    icon?: ReactNode;
    isInBottomSection?: true;
    order: number;
    showChildren?: boolean;
    hasPermission?: boolean;
  }
}

export type MenuPagesReturnType = ReturnType<typeof menuPages>;

const menuPages = (user: any, myRole: PermissionAccess, isHomeService: boolean): RouteObject[] => {
  let pages: RouteObject[] = [
    {
      path: "/",
      element: (
        <Suspense fallback={<CentredSpinnerBox />}>
          <HomePage />
        </Suspense>
      ),
      title: "home",
      icon: <HomeIcon />,
      order: 1,
    },
  ];

  const services: RouteObject = {
    path: "services",
    element: <ServicesPage />,
    title: "services",
    icon: <UserReading />,
    order: 5,
  };

  if (
    myRole["hasServicesAndPackagesView"]?.viewAccess ||
    myRole["hasServicesAndPackagesManage"]?.viewAccess
  )
    pages = [...pages, services];

  const offers: RouteObject = {
    path: "offers",
    element: <Offers />,
    title: "offers",
    icon: <Ticket />,
    order: 6,
  };

  if (myRole["hasPackagesManage"]?.viewAccess) pages = [...pages, offers];

  const calendar: RouteObject = {
    path: "calendar",
    element: (
      <Suspense fallback={<CentredSpinnerBox />}>
        <CalendarPage />
      </Suspense>
    ),
    title: "calendar",
    icon: <Calendar />,
    order: 2,
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<CentredSpinnerBox />}>
            <CalendarPage />
          </Suspense>
        ),
        order: 2,
      },
      {
        path: "create-appointment/:clientId",
        element: (
          <Suspense fallback={<CentredSpinnerBox />}>
            <CalendarPage />
          </Suspense>
        ),
        order: 2,
      },
      {
        path: "edit-appointment/:appointmentId",
        element: (
          <Suspense fallback={<CentredSpinnerBox />}>
            <CalendarPage />
          </Suspense>
        ),
        order: 2,
      },
    ],
  };

  if (
    myRole["hasPersonalAppointment"]?.viewAccess ||
    myRole["hasNonPersonalAppointment"]?.viewAccess
  )
    pages = [...pages, calendar];

  const clients: RouteObject = {
    path: "clients",
    title: "clients",
    icon: <FramedUser />,
    order: 3,
    children: [
      { index: true, element: <Clients />, order: 3 },
      { path: ":id", element: <ClientProfile />, order: 3 },
    ],
  };
  if (myRole["hasClients"]?.viewAccess) pages = [...pages, clients];

  const staff: RouteObject = {
    path: "staff",
    element: <Staff />,
    title: "staff",
    icon: <UserFile />,
    order: 4,
  };
  if (myRole["hasStaff"]?.viewAccess) pages = [...pages, staff];

  const finances: RouteObject = {
    title: "finances",
    icon: <Report />,
    order: 7,
    showChildren: true,
    children: [
      {
        path: "sales",
        order: 1,
        element: <Sales />,
        title: "sales",
        hasPermission: myRole["hasAllSales"]?.viewAccess || myRole["hasDailySales"]?.viewAccess,
      },
      {
        path: "transactions-logs",
        order: 2,
        element: <TransactionsLogs />,
        title: "transactionsLogs",
        hasPermission: myRole["hasAllSales"]?.viewAccess || myRole["hasDailySales"]?.viewAccess,
      },
      {
        path: "expenses",
        order: 3,
        element: <Expenses />,
        title: "expenses",
        hasPermission: myRole["hasExpenses"]?.viewAccess,
      },
      {
        path: "client-wallets",
        order: 4,
        element: <ClientWallets />,
        title: "clientWallets",
        hasPermission: myRole["hasClientWalletsPermission"]?.viewAccess,
      },
    ],
  };

  if (
    myRole["hasAllSales"]?.viewAccess ||
    myRole["hasDailySales"]?.viewAccess ||
    myRole["hasExpenses"]?.viewAccess
  )
    pages = [...pages, finances];

  const inventory: RouteObject = {
    path: "inventory",
    title: "inventory",
    icon: <Inventory />,
    order: 8,
    children: [
      { index: true, element: <InventoryPage />, order: 8 },
      {
        path: "product/:productId",
        element: <ProductDetailPage />,
        order: 8,
      },
    ],
  };

  if (myRole["hasProducts"]?.viewAccess) pages = [...pages, inventory];

  const resources: RouteObject = {
    path: "resources",
    element: <Resources />,
    title: "resources",
    icon: <ResourcesIcon fill="text.blank" />,
    order: 5,
  };

  if (!isHomeService && myRole["hasResourceManageAccess"]?.viewAccess)
    pages = [...pages, resources];

  const settings: RouteObject = {
    path: "settings",
    element: <Settings />,
    title: "settings",
    icon: <Wrenches />,
    isInBottomSection: true,
    order: 9,
  };
  if (user.isOwnerOrAdmin) pages = [...pages, settings];

  return pages.sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0));
};

export { menuPages };

const routes = (user: any, myRole: PermissionAccess, isHomeService: boolean): RouteObject[] => {
  const menu = menuPages(user, myRole, isHomeService);
  const isLoggedIn = user ? <NotFoundPage /> : <Login />;

  const userToken = localStorage.getItem(ACCESS_TOKEN);

  return [
    {
      element: userToken ? <NavigationMenuLayout /> : <Login />,
      order: 0,
      children: [
        ...menu,

        { path: "/profile", element: <Profile />, order: 0 },
        {
          path: "settings/peep-subscription",
          element: user.isOwnerOrAdmin ? <PeepSubscriptions /> : isLoggedIn,
          order: 0,
        },
        {
          path: "settings/account-settings",
          element: user.isOwnerOrAdmin ? <AccountsSettings /> : isLoggedIn,
          order: 0,
        },
        {
          path: "settings/roles",
          element: user.isOwnerOrAdmin ? <Permissions /> : isLoggedIn,
          order: 0,
        },
        {
          path: "settings/payment-types",
          element: user.isOwnerOrAdmin ? <PaymentTypes /> : isLoggedIn,
          order: 0,
        },
        {
          path: "settings/sms",
          element: user.isOwnerOrAdmin ? <SMSReminders /> : isLoggedIn,
          order: 0,
        },
        {
          path: "settings/invoice-sequencing",
          element: user.isOwnerOrAdmin ? <InvoiceSequencing /> : isLoggedIn,
          order: 0,
        },
        {
          path: "settings/online-booking",
          element: user.isOwnerOrAdmin && !user.isEmployee ? <OnlineBooking /> : isLoggedIn,
          order: 0,
        },
        {
          path: "settings/home-service",
          element: user.isOwnerOrAdmin && !user.isEmployee ? <HomeServiceSettings /> : isLoggedIn,
          order: 0,
        },
        {
          path: "invoice/:invoiceId",
          element: <InvoicePage />,
          order: 0,
        },
        {
          path: "expenses",
          element: <Expenses />,
          order: 0,
        },
      ],
    },

    {
      element: <AuthLayout />,
      order: 0,
      children: [
        { path: "/login", element: <Login />, order: 0 },
        { path: "/forgot-password", element: <ForgotPassword />, order: 0 },
        { path: "/reset-password/:token", element: <ResetPassword />, order: 0 },
      ],
    },

    { path: "*", element: isLoggedIn, order: 0 },
  ];
};

export default routes;
