import {
  PaymentTransactionLogType,
  WalletTransactionLog,
  WalletTransactionLogSource,
  WalletTransactionLogType,
} from "types/Transactions";
import http from "utils/http";

function stringifyArray(array: string[]) {
  return array.length < 1 ? "" : array.toString();
}

export type PaginatedResponseType = {
  count: number;
  next: string;
  previous: string;
  totalPages: number;
};

export type WalletTransactionLogsParamsType = {
  page: number;
  startDate: string;
  endDate: string;
  createdBy?: number[];
  type?: WalletTransactionLogType[];
  source?: WalletTransactionLogSource[];
  paymentMethod?: string[];
  search?: string;
  ordering?: string;
  excelDownload?: boolean;
};

export type PaymentTransactionLogsParamsType = {
  page: number;
  startDate: string;
  endDate: string;
  createdBy?: number[];
  type?: PaymentTransactionLogType[];
  paymentMethod?: string[];
  search?: string;
  ordering?: string;
  excelDownload?: boolean;
};


export type WalletTransactionLogsResponseType = PaginatedResponseType & {
  results: WalletTransactionLog[];
};

const transactionsApi = {
  getTransactionsDashboardData: async ({
    startDate,
    endDate,
    outlet,
  }: {
    startDate: string;
    endDate: string;
    outlet: number;
  }) =>
    http.get(
      `sales/transactions_dashboard/?start_date=${startDate}&end_date=${endDate}&outlet=${outlet ? outlet : ""
      }`
    ),
  getWalletTransactionLogs: async ({
    page,
    startDate,
    endDate,
    type,
    createdBy,
    source,
    paymentMethod,
    search,
    ordering,
  }: WalletTransactionLogsParamsType) => {
    const createdByParam = createdBy
      ? `created_by=${stringifyArray(createdBy.join().split(","))}`
      : "";
    const searchParam = search ? `&search=${search}` : "";
    const orderingParam = ordering ? `&order_by=${ordering}` : "";
    const typeParam = type ? `&type=${stringifyArray(type.join().split(","))}` : "";

    const sourceParam = source ? `&source=${stringifyArray(source.join().split(","))}` : "";

    const paymentMethodParam = paymentMethod
      ? `&payment_method=${stringifyArray(paymentMethod.join().split(","))}`
      : "";

    return http.get(
      `sales/wallet_transactions/?page=${page}&start_date=${startDate}&end_date=${endDate}&${createdByParam}${typeParam}${sourceParam}${paymentMethodParam}${searchParam}${orderingParam}`
    );
  },

  getWalletTransactionLogsExcel: async ({
    startDate,
    endDate,
    type,
    createdBy,
    source,
    paymentMethod,
    search,
    excelDownload,
    ordering,
  }: Omit<WalletTransactionLogsParamsType, "page">) => {
    const createdByParam = createdBy
      ? `created_by=${stringifyArray(createdBy.join().split(","))}`
      : "";
    const searchParam = search ? `&search=${search}` : "";
    const orderingParam = ordering ? `&order_by=${ordering}` : "";
    const typeParam = type ? `&type=${stringifyArray(type.join().split(","))}` : "";

    const sourceParam = source ? `&source=${stringifyArray(source.join().split(","))}` : "";

    const paymentMethodParam = paymentMethod
      ? `&payment_method=${stringifyArray(paymentMethod.join().split(","))}`
      : "";

    return http.get<ArrayBuffer>(
      `sales/wallet_transactions/?start_date=${startDate}&end_date=${endDate}&${createdByParam}${typeParam}${sourceParam}${paymentMethodParam}${searchParam}${orderingParam}&excel_download=True`,
      {
        responseType: "arraybuffer",
        transformRequest: [],
        transformResponse: [],
      }
    );
  },

  getPaymentTransactionLogs: async ({
    page,
    startDate,
    endDate,
    createdBy,
    type: types,
    paymentMethod,
    search,
    ordering,
  }: PaymentTransactionLogsParamsType) => {
    const createdByParam = createdBy
      ? `created_by=${stringifyArray(createdBy.join().split(","))}`
      : "";
    const searchParam = search ? `&search=${search}` : "";
    const orderingParam = ordering ? `&order_by=${ordering}` : "";

    const typeParam = types ? `&transaction_type=${stringifyArray(types.map(type => type.toUpperCase()).join().split(","))}` : "";

    const paymentMethodParam = paymentMethod
      ? `&payment_method=${stringifyArray(paymentMethod.join().split(","))}`
      : "";

    return http.get(
      `sales/payment_transactions/?page=${page}&start_date=${startDate}&end_date=${endDate}&${createdByParam}${typeParam}${paymentMethodParam}${searchParam}${orderingParam}`
    );
  },

  getPaymentTransactionLogsExcel: async ({
    startDate,
    endDate,
    createdBy,
    type: types,
    paymentMethod,
    search,
    ordering,
  }: Omit<PaymentTransactionLogsParamsType, "page">) => {
    const createdByParam = createdBy
      ? `created_by=${stringifyArray(createdBy.join().split(","))}`
      : "";
    const searchParam = search ? `&search=${search}` : "";
    const orderingParam = ordering ? `&order_by=${ordering}` : "";

    const typeParam = types ? `&transaction_type=${stringifyArray(types.map(t => t.toUpperCase()).join().split(","))}` : "";

    const paymentMethodParam = paymentMethod
      ? `&payment_method=${stringifyArray(paymentMethod.join().split(","))}`
      : "";

    return http.get<ArrayBuffer>(
      `sales/payment_transactions/?start_date=${startDate}&end_date=${endDate}&${createdByParam}${typeParam}${paymentMethodParam}${searchParam}${orderingParam}&excel_download=True`,
      {
        responseType: "arraybuffer",
        transformRequest: [],
        transformResponse: [],
      }
    );
  },

};

export default transactionsApi;
