import { createSlice } from "@reduxjs/toolkit";
import downloadResponse from "store/sales/downloadResponse";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import {
  PeepGiftCreditSale,
  ProductSale,
  RefundLog,
  Sales,
  SubscriptionSale,
  VoucherSale,
} from "types/Sales";

import { SliceStatus } from "../utils";
import api, { PurchasableSalesResponseType } from "./salesApi";

type SalesSliceState = {
  productSales: PurchasableSalesResponseType & { results: ProductSale[] };

  subscriptionSales: PurchasableSalesResponseType & { results: SubscriptionSale[] };

  voucherSales: PurchasableSalesResponseType & { results: VoucherSale[] };

  peepGiftCreditSales: PurchasableSalesResponseType & { results: PeepGiftCreditSale[] };

  refundsLog: Omit<PurchasableSalesResponseType, "totalSales"> & { results: RefundLog[] };

  dailySales: {
    today: Sales;
    yesterday: Sales;
  };

  status: SliceStatus;
};

const initialSalesDataState: Sales = {
  date: "",
  totalSales: "0.000",
  totalUnpaid: "0.000",
  totalRedemptions: "0.000",
  totalVoucherRedemptions: "0.000",
  cashMovement: [],
  salesBreakdown: [],
  appointments: {
    count: 0,
    total: null,
  },
  products: {
    count: 0,
    total: null,
  },
  subscriptions: {
    count: 0,
    total: null,
  },
  vouchers: {
    count: 0,
    total: null,
  },
};

const initialState: SalesSliceState = {
  dailySales: {
    today: initialSalesDataState,
    yesterday: initialSalesDataState,
  },
  productSales: { count: 0, next: "", previous: "", results: [], totalSales: 0, totalPages: 0 },
  subscriptionSales: {
    count: 0,
    next: "",
    previous: "",
    results: [],
    totalSales: 0,
    totalPages: 0,
  },
  voucherSales: {
    count: 0,
    next: "",
    previous: "",
    results: [],
    totalSales: 0,
    totalPages: 0,
  },
  peepGiftCreditSales: {
    count: 0,
    next: "",
    previous: "",
    results: [],
    totalSales: 0,
    totalPages: 0,
  },
  refundsLog: {
    count: 0,
    next: "",
    previous: "",
    results: [],
    totalPages: 0,
  },
  status: SliceStatus.IDLE,
};

export const getAllPeepGiftCreditSalesData = createThunkFromApiWithType(
  "sales/salesPeepGiftCredits/",
  api.getPeepGiftCardSalesData
);

export const appendAllPeepGiftCreditSalesData = createThunkFromApiWithType(
  "sales/appendSalesPeepGiftCredits/",
  api.getPeepGiftCardSalesData
);

export const getAllVoucherSalesData = createThunkFromApiWithType(
  "sales/salesVouchers/",
  api.getVoucherSalesData
);

export const appendAllVoucherSalesData = createThunkFromApiWithType(
  "sales/appendSalesVouchers/",
  api.getVoucherSalesData
);

export const getAllSubscriptionSalesData = createThunkFromApiWithType(
  "sales/salesSubscriptions/",
  api.getSubscriptionSalesData
);

export const appendAllSubscriptionSalesData = createThunkFromApiWithType(
  "sales/appendSalesSubscriptions/",
  api.getSubscriptionSalesData
);

export const getDailySalesData = createThunkFromApiWithType("sales/salesDaily/", api.getDailySales);

export const getAllProductSalesData = createThunkFromApiWithType(
  "sales/salesProducts/",
  api.getProductSalesData
);

export const appendAllProductSalesData = createThunkFromApiWithType(
  "sales/appendSalesProducts/",
  api.getProductSalesData
);

export const getProductSalesExcelData = createThunkFromApiWithType(
  "expenses/getProductSalesExcelData",
  api.getProductSalesExcelData,
  {
    handleResponse: ({ response }) => {
      downloadResponse(response);
    },
  }
);

export const getSubscriptionSalesExcelData = createThunkFromApiWithType(
  "expenses/getSubscriptionSalesExcelData",
  api.getSubscriptionSalesExcelData,
  {
    handleResponse: ({ response }) => {
      downloadResponse(response);
    },
  }
);

export const getVoucherSalesExcelData = createThunkFromApiWithType(
  "expenses/getVoucherSalesExcelData",
  api.getVoucherSalesExcelData,
  {
    handleResponse: ({ response }) => {
      downloadResponse(response);
    },
  }
);

export const getRefundsLog = createThunkFromApiWithType(
  "sales/salesRefundsLog/",
  api.getRefundsLog
);

export const appendRefundsLog = createThunkFromApiWithType(
  "sales/appendRefundsLog/",
  api.getRefundsLog
);

export const getRefundsLogExcel = createThunkFromApiWithType(
  "sales/getRefundsLogExcel",
  api.getRefundsLogExcel,
  {
    handleResponse: ({ response }) => {
      downloadResponse(response);
    },
  }
);

export const purchasableSalesSlice = createSlice({
  name: "purchasableSales",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers.addCase(getDailySalesData.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getDailySalesData.fulfilled, (state, { payload }) => {
      state.dailySales = payload;
      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getDailySalesData.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    reducers.addCase(getAllProductSalesData.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getAllProductSalesData.fulfilled, (state, { payload }) => {
      state.productSales = payload;
      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getAllProductSalesData.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    reducers.addCase(appendAllProductSalesData.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(appendAllProductSalesData.fulfilled, (state, { payload }) => {
      state.status = SliceStatus.IDLE;
      state.productSales.results = [...state.productSales.results, ...payload.results];
    });

    reducers.addCase(appendAllProductSalesData.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    // SUBSCRIPTION SALES REDUCERS
    reducers.addCase(getAllSubscriptionSalesData.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getAllSubscriptionSalesData.fulfilled, (state, { payload }) => {
      state.subscriptionSales = payload;
      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getAllSubscriptionSalesData.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    reducers.addCase(appendAllSubscriptionSalesData.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(appendAllSubscriptionSalesData.fulfilled, (state, { payload }) => {
      state.status = SliceStatus.IDLE;
      state.subscriptionSales.results = [...state.subscriptionSales.results, ...payload.results];
    });

    reducers.addCase(appendAllSubscriptionSalesData.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    // VOUCHER SALES REDUCERS
    reducers.addCase(getAllVoucherSalesData.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getAllVoucherSalesData.fulfilled, (state, { payload }) => {
      state.voucherSales = payload;
      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getAllVoucherSalesData.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    reducers.addCase(appendAllVoucherSalesData.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(appendAllVoucherSalesData.fulfilled, (state, { payload }) => {
      state.status = SliceStatus.IDLE;
      state.voucherSales.results = [...state.voucherSales.results, ...payload.results];
    });

    reducers.addCase(appendAllVoucherSalesData.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    // PEEP GIFT CREDIT SALES REDUCERS
    reducers.addCase(getAllPeepGiftCreditSalesData.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getAllPeepGiftCreditSalesData.fulfilled, (state, { payload }) => {
      state.peepGiftCreditSales = payload;
      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getAllPeepGiftCreditSalesData.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    reducers.addCase(appendAllPeepGiftCreditSalesData.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(appendAllPeepGiftCreditSalesData.fulfilled, (state, { payload }) => {
      state.status = SliceStatus.IDLE;
      state.peepGiftCreditSales.results = [
        ...state.peepGiftCreditSales.results,
        ...payload.results,
      ];
    });

    reducers.addCase(appendAllPeepGiftCreditSalesData.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    // REFUNDS LOG REDUCERS
    reducers.addCase(getRefundsLog.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });
    reducers.addCase(getRefundsLog.fulfilled, (state, { payload }) => {
      state.refundsLog = payload;
      state.status = SliceStatus.IDLE;
    });
    reducers.addCase(getRefundsLog.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    reducers.addCase(appendRefundsLog.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });
    reducers.addCase(appendRefundsLog.fulfilled, (state, { payload }) => {
      state.status = SliceStatus.IDLE;
      state.refundsLog.results = [...state.refundsLog.results, ...payload.results];
    });
    reducers.addCase(appendRefundsLog.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(getRefundsLogExcel.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });
    reducers.addCase(getRefundsLogExcel.fulfilled, (state) => {
      state.status = SliceStatus.IDLE;
    });
    reducers.addCase(getRefundsLogExcel.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });
  },
});

export default purchasableSalesSlice.reducer;
